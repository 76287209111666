.input-amount .MuiFormControl-root {
  width: 100%;
}
.input-amount .MuiInputBase-inputMarginDense {
  height: 55px;
}
.input-amount .MuiInputBase-root .MuiInputBase-input {
  text-align: center;
  font-size: 30px;
  font-family: 'SFDisplay-Semibold' !important;
}
.input-amount .MuiInput-underline::before {
  border-bottom: 0.5px solid #efefef !important;
}
.input-amount .MuiInput-underline.Mui-error::after {
  border-bottom: 0.5px solid #e64646 !important;
}
.input-amount .MuiInput-underline::after {
  border-bottom: 0.5px solid #0066b3 !important;
}
