/* .text-field-custom .MuiFormControl-root {
  width: 90%;
} */
.text-field-custom .MuiInputBase-root {
  border-radius: 8px;
}
.text-field-custom .MuiInputBase-root .Mui-disabled {
  background-color: #efefef;
  border-radius: 8px;
  color: #18191f;
}
