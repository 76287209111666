//USE THIS FILE TO OVERRIDE CSS VARIABLES

$primary: #215bc1;
$black-200: #f2f2f2;
$black-250: #4e5966;
$black-310: #bfbebe;
$black-320: #565869;
$black-400: #7a7c8b;
$black-450: #31333f;
$black-500: #18191f;

$red-450: #e64646;
