.diaglog-loading {
  padding-top: 50%;
}
.container-loading {
  text-align: center;
}
.container-loading > div {
  background: #1c86ee;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  animation: 1.2s load infinite linear alternate;
}
.container-loading div:nth-child(1) {
  transform: scale(0);
  animation-delay: 0s;
}
.container-loading div:nth-child(2) {
  transform: scale(0);
  animation-delay: 0.4s;
}
.container-loading div:nth-child(3) {
  transform: scale(0);
  animation-delay: 0.8s;
}
@keyframes load {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.8);
  }
}
