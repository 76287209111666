table tr {
  height: 40px !important;
}
table#history tr {
  height: auto !important;
}
table#history tbody td {
  padding: 15px !important;
}
table td {
  border: none !important;
}
table tr td:last-child {
  text-align: right;
}
