body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  font-family: 'SFDisplay' !important;
  color: #24272b;
  /* background-color: #f0f4f6 */
  /*background-color: #fff;*/
  min-height: 100vh;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /*background: linear-gradient(90deg, #006DFE 0%, #00ABF9 98.91%);*/
}
body.grey {
  background-color: #f0f4f6;
}
.medium {
  font-family: 'SFDisplay-Medium' !important;
}
.semibold {
  font-family: 'SFDisplay-Semibold' !important;
}

.App {
  text-align: center;
  --main-bg-color: linear-gradient(90deg, rgb(0, 109, 254) 0%, rgb(0, 171, 249) 98.91%);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}
table td {
  padding: 8px 15px !important;
}
input {
  width: 100%;
}
.page {
  padding: 15px 15px 70px 15px;
}
.page-no-bottom {
  padding-bottom: 0;
}
.padding-top-page {
  padding-top: 20px;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-highlight {
  color: #459beb;
}
.bottom-fixed-button-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 15px;
  background-color: #fff;
}
.background-transparent {
  background-color: transparent !important;
}
button {
  text-transform: unset !important;
}
.bg-white {
  background-color: #fff;
}
.option-drawer {
  padding: 0 !important;
}
.option-drawer > div {
  text-align: center;
  padding: 18px 12px;
}
.option-drawer > div > div {
  padding: 0 !important;
}
.option-drawer > div > div.black > span:first-child {
  color: #24272b;
}
.option-drawer > div > div.grey > span:first-child {
  color: #727f8c;
}
.page-bg-white {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
}
.icon-zp-64 {
  font-size: 64px;
}
.icon-zp-48 {
  font-size: 48px;
}
.icon-zp-40 {
  font-size: 40px;
}
.icon-zp-28 {
  font-size: 28px;
}
.icon-zp-25 {
  font-size: 25px;
}
.icon-zp-20 {
  font-size: 20px;
}
.icon-zp-16 {
  font-size: 16px;
}
.icon-zp-10 {
  font-size: 10px;
}
.icon-circle {
  border: 1px solid #000;
  border-radius: 50%;
}
.grey {
  color: #727f8c;
  border-color: #727f8c;
}
.blue {
  color: #018fe5;
}
.yellow {
  color: #f6b501;
}
.green {
  color: #129d5a;
}
.bg-zp-gradient {
  background-image: linear-gradient(to right, #0271fe, #06a9fc);
  background-image: -moz-linear-gradient(to right, #0271fe, #06a9fc);
  background-image: -webkit-linear-gradient(to right, #0271fe, #06a9fc);
  background-image: -o-linear-gradient(to right, #0271fe, #06a9fc);
}
.datepicker.ios .datepicker-navbar {
  border-bottom: 1px solid #dbdbdb;
}
.datepicker.ios .datepicker-wheel .datepicker-scroll > li:nth-child(6) {
  transform: scale(1.2);
  font-family: 'SFDisplay-Medium' !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-bold {
  font-family: 'SFDisplay-Semibold' !important;
}

.container {
  background-color: white !important;
  height: 100vh;
}

.bottom-div {
  position: fixed;
  left: 0;
  bottom: 20px;
  right: 0;
  background-color: inherit;
  padding-right: 15px;
  padding-left: 15px;
}

.button-v2 {
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  box-shadow: none !important;
  padding: 10px !important;
  color: white !important;
  background: #2c91fa !important;
}

.ff-b {
  font-family: 'SFDisplay-Bold' !important;
}

.ff-sb {
  font-family: 'SFDisplay-Semibold' !important;
}

.ff-r {
  font-family: 'SFDisplay' !important;
}

.fs-13 {
  font-size: 13px;
}
.fs-11 {
  font-size: 11px;
}
.lh-20 {
  line-height: 20px;
}
