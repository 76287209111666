@import '../../assets/scss/color.scss';

.divider-custom.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 2px dashed $black-310;
}

.divider-custom.ant-divider-horizontal.ant-divider.ant-divider-inner-text {
  color: red;
}
