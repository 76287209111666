.animation-circle {
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;
  background: rgba(188, 237, 243, 0.889);
  animation: breathe 2s linear infinite;
}
@keyframes breathe {
  0% {
    box-shadow: 0 0 0 5px rgba(188, 237, 243, 0.3), 0 0 0 10px rgba(188, 237, 243, 0.3),
      0 0 0 15px rgba(188, 237, 243, 0.3);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3), 0 0 0 30px rgba(188, 237, 243, 0.3),
      0 0 0 45px rgba(188, 237, 243, 0.3);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(188, 237, 243, 0.3), 0 0 0 10px rgba(188, 237, 243, 0.3),
      0 0 0 15px rgba(188, 237, 243, 0.3);
  }
}
