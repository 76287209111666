.basic-button-custom {
  height: fit-content;
  &.ant-btn-default {
    border-color: unset;
  }
  > span {
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 700;
  }
}
