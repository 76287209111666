@import './assets/scss/color.scss';

.mt {
  &-6 {
    margin-top: 6px;
  }
  &-16 {
    margin-top: 16px;
  }
  &-24 {
    margin-top: 24px;
  }
  &-20 {
    margin-top: 20px;
  }
  &-32 {
    margin-top: 32px;
  }
  &-36 {
    margin-top: 36px;
  }
  &-42 {
    margin-top: 42px;
  }
}
.ml {
  &-8 {
    margin-left: 8px;
  }
  &-16 {
    margin-left: 16px;
  }
}
.mr {
  &-16 {
    margin-right: 16px;
  }
}
.mb {
  &-1-5 {
    margin-bottom: 1.5px;
  }
}
.rounded {
  &-8 {
    border-radius: 8px;
  }
}

.gap {
  &-6 {
    gap: 6px;
  }
  &-12 {
    gap: 12px;
  }
}

.pt {
  &-12 {
    padding-top: 12px;
  }
  &-16 {
    padding-top: 16px;
  }
  &-24 {
    padding-top: 24px;
  }
}

.py {
  &-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.p {
  &-16 {
    padding: 16px;
  }
  &b {
    &-32 {
      padding-bottom: 32px;
    }
  }
  &x {
    &-12 {
      padding-left: 12px;
      padding-right: 12px;
    }
    &-16 {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
.bg {
  &-black200 {
    background-color: $black-200;
  }
  &-black500 {
    background-color: $black-500;
  }
  &-white {
    background-color: white;
  }
}

.text {
  &-start {
    text-align: start;
  }
  &-black250 {
    color: $black-250;
  }
  &-black300 {
    color: $black-310;
  }
  &-black320 {
    color: $black-320;
  }
  &-black400 {
    color: $black-400;
  }
  &-black450 {
    color: $black-450;
  }
  &-red450 {
    color: $red-450;
  }
  &-red500 {
    color: $black-500;
  }
  &-14-18 {
    font-size: 14px;
    line-height: 18px;
  }
  &-14-20 {
    font-size: 14px;
    line-height: 20px;
  }
  &-14-20-400 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  &-15-18-400 {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }
  &-15-18 {
    font-size: 15px;
    line-height: 18px;
  }
  &-15-20 {
    font-size: 15px;
    line-height: 20px;
  }
  &-16-18 {
    font-size: 16px;
    line-height: 18px;
  }
  &-16-20 {
    font-size: 16px;
    line-height: 20px;
  }
  &-20-24-700 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify {
  &-center {
    justify-content: center;
  }
  &-start {
    justify-content: start;
  }
  &-end {
    justify-content: end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
}

.items {
  &-center {
    align-items: center;
  }
  &-start {
    align-items: start;
  }
  &-end {
    align-items: end;
  }
}

.w {
  &-16 {
    width: 16px;
  }
  &-36 {
    width: 36px;
  }
}

.h {
  &-16 {
    height: 16px;
  }
  &-18 {
    height: 18px;
  }
  &-36 {
    height: 36px;
  }
  &-screen {
    height: 100vh;
  }
  &-full {
    height: 100%;
  }
}
.min-h {
  &-screen {
    min-height: 100vh;
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
