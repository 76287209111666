.swipeable-drawer-custom {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  .ant-drawer-header {
    padding: 16px;
    justify-content: unset;
    position: static;
    top: 20px;
    .ant-drawer-header-title {
      flex: unset;
      > button {
        color: #2e2e2e;
      }
    }
    .ant-drawer-extra {
      font-size: 18px;
      line-height: 22px;
      font-family: 'SFDisplay-Medium' !important;
      color: #2e2e2e;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
}
